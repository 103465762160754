<template>
  <div class="not-found">
    <a-result status="404" title="404" sub-title="页面不存在，请检查路径是否正确">
      <template #extra>
        <a-button type="primary" @click="back">返回上一页</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.not-found {
  padding-top: 120px;
}
</style>